import { get, isEmpty } from 'lodash';
import { RESET } from 'jotai/utils';
import store from '..';
import { contentListStateSegment } from '../stateSegments/contentListSegment';
import {
  currentAvailableLanguagesStateSegment,
  currentSelectedLanguageStateSegment,
} from '../stateSegments/availableLanguagesStateSegment';
import {
  currentLanguageTabStateSegment,
  currentLanguageTabsListStateSegment,
} from '../stateSegments/languageTabsStateSegment';
import { convertTextToLowerCase } from '../../../utils/convertTextToLowerCase';
import { Option } from '../../../types/SelectInput';
import {
  MarketingFormField,
  MarketingFormFieldRequest,
  SecondaryFormApiField,
  VersionPayload,
} from '../../../types/MarketingBox';
import { ParamsUsed } from '../../../types/store/markettingContentGeneration/majorProcessActions';
import { contentImagesStateSegment } from '../stateSegments/contentImagesStateSegment';
import {
  ContentType,
  CONVERT,
  EMAIL_ATTRIBUTES_NAME,
  MAX_EMAIL_MODULES_SELECTION_LIMIT,
  SortOrder,
  operationType,
  MAX_RECENT_TRANSLATIONS_LIMIT,
  EMAIL_INTRO_NAME,
} from '../../../constants/common';
import { contentTranslationStateSegment } from '../stateSegments/translationSegment';
import { contentPaginationStateSegment } from '../stateSegments/contentPaginationStateSegment';
import { preGenerateCliamsStateSegment } from '../stateSegments/preGenerateClaimsStateSegment';
import {
  activeEmailScreenStateSegment,
  emailContentParentId,
  moduleContentsStateSegment,
  selectedModulesStateSegment,
} from '../stateSegments/emailModulesStateSegment';
import {
  createUniqueModuleKey,
  getContentGroupId,
  getSessionId,
} from '../../../utils/commonUtils';
import {
  ModuleContent,
  ModuleStatus,
  SelectedModule,
} from '../../../types/store/markettingContentGeneration/emailModules';
import imagesDataStateSegment from '../stateSegments/imagesDataStateSegment';
import { contentFeedbackStateSegment } from '../stateSegments/feedbackSegment';
import { contentClaimsStateSegment } from '../stateSegments/contentClaimsStateSegment';
import { refineOptionsStateSegment } from '../stateSegments/refineOptionsStateSegment';
import { userDetailSegment } from '../../globalStore/stateSegments/userDetailSegment';
import { contentParamsSegment } from '../stateSegments/paramsSegment';
import { GeneratedContentDataType } from '../../../types/transformedData/MarkettingContentGeneration';
import { GeneratedEmailModuleContentDataType } from '../../../types/Content';
import { imageCaptionStateSegment } from '../stateSegments/imageCaptionStateSegment';
import { recentTranslationsStateSegment } from '../stateSegments/recentTranslationsStateSegment';

export const getMappedLabel = (collection: Option[], id: string) => {
  const { label } = collection.find((item) => item.id === id) || { label: '' };
  return convertTextToLowerCase(label);
};

export const getMappedName = (collection: Option[], selectedName: string) => {
  const { name } = collection.find(
    (item) => item.name?.toLowerCase() === selectedName.toLowerCase()
  ) || { name: '' };

  return convertTextToLowerCase(name);
};

export const getMappedNameArr = (
  collection: Option[],
  selectedName: string[]
) => {
  const options = collection.filter((item) =>
    selectedName.includes(item.name || '')
  );

  return options.map((option) => convertTextToLowerCase(option.name));
};

export const getCurrentSelectedLanguage = () => {
  const { currentSelectedLanguage } = store.get(contentParamsSegment);
  return currentSelectedLanguage;
};

export const getVersionIds = () => {
  const versionIds = store
    .get(contentListStateSegment)
    .contentList.map((contentItem) =>
      get(contentItem, `${getCurrentSelectedLanguage()}.id`, '')
    );

  return versionIds;
};

export const getParentId = () => {
  const [parentId] = getVersionIds();
  return parentId;
};

export const getGenerateContentPayload = (
  paramsUsed: ParamsUsed
): MarketingFormFieldRequest => {
  const selectedBrandLabel = getMappedName(
    paramsUsed.currentAvailableBrandOptions,
    paramsUsed.currentSelectedBrand
  );
  const selectedIndicationName = getMappedName(
    paramsUsed.currentAvailableIndicationOptions,
    paramsUsed.currentSelectedIndication
  );
  const selectedCountryName = getMappedName(
    paramsUsed.currentAvailableCountryOptions,
    paramsUsed.currentSelectedCountry
  );
  const selectedAudienceLabel = getMappedName(
    paramsUsed.currentAvailableAudienceOptions,
    paramsUsed.currentSelectedAudience
  );
  const selectedContentTypeLabel =
    paramsUsed.currentSelectedContentType === ContentType.DSP.toLowerCase()
      ? getMappedName(
          paramsUsed.currentAvailableContentTypeOptions,
          paramsUsed.currentSelectedContentType
        )
      : paramsUsed.currentSelectedContentType.toLowerCase();
  const selectedPersonaLabel = getMappedName(
    paramsUsed.currentAvailableSegmentOptions,
    paramsUsed.currentSelectedSegment
  );

  const selectedSections = get(paramsUsed, 'currentSelectedSections', []);
  const selectedSectionsLabels: string[] = [];

  if (!isEmpty(selectedSections)) {
    selectedSections.forEach((section: string) => {
      selectedSectionsLabels.push(
        getMappedName(paramsUsed.currentAvailableSectionOptions, section)
      );
    });
  }

  const selectedToneLabel = getMappedName(
    paramsUsed.currentAvailableToneOptions,
    paramsUsed.currentSelectedTone
  );

  const selectedPlatform = getMappedName(
    paramsUsed.currentAvailablePlatformOptions,
    paramsUsed.currentSelectedPlatform
  );

  const selectedTopicsLabel = getMappedNameArr(
    paramsUsed.currentAvailableTopicOptions,
    paramsUsed.currentSelectedTopic
  );

  const selectedActionLabel =
    selectedContentTypeLabel === ContentType.DSP.toLowerCase()
      ? getMappedName(
          paramsUsed.currentAvailableActionOptions,
          paramsUsed.currentSelectedAction
        )
      : paramsUsed.currentSelectedAction;

  const selectedDestinationFormatLabel =
    selectedActionLabel.toLowerCase() === CONVERT.toLowerCase()
      ? getMappedName(
          paramsUsed.currentAvailableDestinationFormatOptions || [],
          paramsUsed.currentSelectedDestinationFormat || ''
        )
      : undefined;

  const selectedLanguageLabel = getMappedName(
    paramsUsed.currentAvailableLanguageOptions,
    paramsUsed.currentSelectedLanguage
  );

  const selectedUnbrandedFlag = paramsUsed.currentSelectedUnbrandedFlag;

  const requestPayload: MarketingFormFieldRequest = {
    action: selectedActionLabel,
    brand: selectedBrandLabel,
    country: selectedCountryName,
    indication: selectedIndicationName === '' ? 'any' : selectedIndicationName,
    targetAudience: selectedAudienceLabel,
    segment: selectedPersonaLabel,
    sections: selectedSectionsLabels as unknown as string, // needs to be corrSected
    contentType:
      selectedContentTypeLabel === 'rte'
        ? selectedContentTypeLabel.toUpperCase()
        : selectedContentTypeLabel,
    tone: selectedToneLabel,
    topics: selectedTopicsLabel,
    platform: selectedPlatform,
    emailModules: paramsUsed.currentSelectedModules,
    destinationFormat:
      selectedDestinationFormatLabel === 'rte'
        ? selectedDestinationFormatLabel.toUpperCase()
        : selectedDestinationFormatLabel,
    fileName: paramsUsed.currentSelectedFile,
    sessionId: getSessionId(),
    contentGroupId: getContentGroupId(),
    language: selectedLanguageLabel,
    unbranded: selectedUnbrandedFlag,
  };

  return requestPayload;
};

export const getRefinementAdditionalParams = (
  paramsUsed: ParamsUsed
): SecondaryFormApiField => {
  const refinePayload = {
    toneStyle: getMappedName(
      paramsUsed.currentAvailableToneStyleOptions,
      paramsUsed.currentSelectedToneStyle
    ),
    exclusions: get(
      paramsUsed,
      'currentSelectedExclusion',
      ''
    ) as unknown as string,
    inclusions: (
      get(paramsUsed, 'currentSelectedInclusion', '') as unknown as string
    )
      .split(',')
      .filter(Boolean),
  };

  return {
    ...refinePayload,
  };
};

export const getMappedOption = (collection: Option[], name: string) => {
  return collection.find(
    (item) => item.name?.toLowerCase() === name.toLowerCase()
  );
};

const getMappedOptionArr = (collection: Option[], name: string[]) => {
  return collection.filter((item) => name.includes(item.name || ''));
};

export const getParamsUsed = (paramsUsed: ParamsUsed) => {
  return {
    action:
      paramsUsed.currentSelectedContentType.toLowerCase() ===
      ContentType.DSP.toLowerCase()
        ? getMappedOption(
            paramsUsed.currentAvailableActionOptions,
            paramsUsed.currentSelectedAction
          )
        : {
            label: paramsUsed.currentSelectedAction,
            value: paramsUsed.currentSelectedAction,
            id: 1,
          },
    brand: getMappedOption(
      paramsUsed.currentAvailableBrandOptions,
      paramsUsed.currentSelectedBrand
    ),
    destinationFormat: getMappedOption(
      paramsUsed.currentAvailableDestinationFormatOptions || [],
      paramsUsed.currentSelectedDestinationFormat || ''
    ),
    indication: getMappedOption(
      paramsUsed.currentAvailableIndicationOptions,
      paramsUsed.currentSelectedIndication
    ),
    country: getMappedOption(
      paramsUsed.currentAvailableCountryOptions,
      paramsUsed.currentSelectedCountry
    ),
    audience: getMappedOption(
      paramsUsed.currentAvailableAudienceOptions,
      paramsUsed.currentSelectedAudience
    ),
    contentType:
      paramsUsed.currentSelectedContentType.toLowerCase() !==
      ContentType.DSP.toLowerCase()
        ? getMappedOption(
            paramsUsed.currentAvailableContentTypeOptions,
            paramsUsed.currentSelectedContentType
          )
        : {
            id: '3',
            label: 'DSP',
            name: 'DSP',
          },
    persona: getMappedOption(
      paramsUsed.currentAvailableSegmentOptions,
      paramsUsed.currentSelectedSegment
    ),
    sections: paramsUsed.currentSelectedSections.map((item: string) =>
      getMappedOption(paramsUsed.currentAvailableSectionOptions, item)
    ),
    tone: getMappedOption(
      paramsUsed.currentAvailableToneOptions,
      paramsUsed.currentSelectedTone
    ),

    platform: getMappedOption(
      paramsUsed.currentAvailablePlatformOptions,
      paramsUsed.currentSelectedPlatform
    ),
    topics: getMappedOptionArr(
      paramsUsed.currentAvailableTopicOptions,
      paramsUsed.currentSelectedTopic
    ),
    toneStyle: getMappedOption(
      paramsUsed.currentAvailableToneStyleOptions,
      paramsUsed.currentSelectedToneStyle
    ),
    exclusions: paramsUsed.currentSelectedExclusion,
    inclusions: paramsUsed.currentSelectedInclusion,
    language: getMappedOption(
      paramsUsed.currentAvailableLanguageOptions,
      paramsUsed.currentSelectedLanguage
    ),
    unbranded: paramsUsed.currentSelectedUnbrandedFlag,
  };
};

export const resetTranslationState = () => {
  const currentSelectedLanguage = getCurrentSelectedLanguage();
  store.set(currentSelectedLanguageStateSegment, currentSelectedLanguage);
  store.set(currentLanguageTabStateSegment, currentSelectedLanguage);
  store.set(currentLanguageTabsListStateSegment, []);
};

export const updateLanguageOptions = (langCode: string) => {
  const languageOptions = store.get(currentAvailableLanguagesStateSegment).data;

  const languageOption = languageOptions.find(
    (currentAvailableLanguage: Option) =>
      currentAvailableLanguage.value === langCode
  );

  const primaryLanguageOption: Option = {
    value: 'en',
    label: 'English',
    id: 'en',
  };

  if (languageOption && languageOption.value) {
    const languageOptionsToUpdate: Option[] = [
      languageOption,
      primaryLanguageOption,
    ];

    store.set(currentLanguageTabsListStateSegment, languageOptionsToUpdate);
    store.set(currentLanguageTabStateSegment, languageOption.value);
  }
};

export const setSectionLoader = ({
  langCode,
  optionId,
  showLoader,
}: {
  langCode: string;
  optionId: string;
  showLoader: boolean;
}) => {
  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      sectionLoaders: {
        ...prev.sectionLoaders,
        [`${langCode}-${optionId}`]: showLoader,
      },
    };
  });
};

export const getSectionLoader = (langCode: string, optionId: string) => {
  const sectionLoaderHashmap: Record<string, boolean> = store.get(
    contentListStateSegment
  ).sectionLoaders;

  return sectionLoaderHashmap[`${langCode}-${optionId}`] || false;
};

export const resetOptionImages = (contentId: string, optionId: string) => {
  store.set(contentImagesStateSegment, (prev) => {
    const imageStateReplica = { ...prev };
    if (
      contentId in imageStateReplica &&
      optionId in imageStateReplica[contentId]
    ) {
      delete imageStateReplica[contentId][optionId];
    }

    return { ...imageStateReplica };
  });
};

export const getContentApiPayloadUsed = (content: unknown) => {
  const apiPayloadUsed = get(
    content,
    `apiPayloadUsed`
  ) as unknown as MarketingFormField &
    SecondaryFormApiField &
    VersionPayload & { contentId: string };

  return apiPayloadUsed;
};

export const getContent = (currentVersion: number) => {
  const { contentList } = store.get(contentListStateSegment);
  const content = get(contentList, `[${currentVersion}]`);

  return content;
};

export const getPrefetchedClaimListIds = (primaryMessages: string[] = []) => {
  const claimsList = store.get(
    preGenerateCliamsStateSegment
  ).preFetchedclaimsList;

  const claimsListIds = primaryMessages.length
    ? claimsList
        ?.filter((claim) => primaryMessages?.includes(claim?.topic))
        ?.map((claim) => claim?.id)
    : claimsList.map((claim) => claim?.id);

  return claimsListIds;
};

export const showLoadersBasedOnContentType = (paramsUsed: {
  contentType: ContentType;
  optionId: string;
  currentVersion: number;
  loaderPath: string;
  showLoader: boolean;
  langCode: string;
  destinationFormat?: string;
}) => {
  if (
    paramsUsed.contentType === ContentType.BANNER ||
    paramsUsed.contentType === ('social' as ContentType) ||
    paramsUsed.contentType === ContentType.SOCIAL ||
    paramsUsed.contentType === ContentType.EMAIL ||
    paramsUsed.destinationFormat === ContentType.BANNER ||
    paramsUsed.destinationFormat === ('social' as ContentType) ||
    paramsUsed.destinationFormat === ContentType.SOCIAL ||
    paramsUsed.destinationFormat === ContentType.EMAIL ||
    paramsUsed.destinationFormat?.toLowerCase() ===
      ContentType.RTE.toLowerCase() ||
    paramsUsed.contentType?.toLowerCase() === ContentType.RTE.toLowerCase()
  ) {
    setSectionLoader({
      langCode: getCurrentSelectedLanguage(),
      optionId: paramsUsed.optionId,
      showLoader: paramsUsed.showLoader,
    });
  }
};

export const isModuleLimitReached = () => {
  const selectedModulesMap = store.get(selectedModulesStateSegment);
  const selectedModulesList = Array.from(selectedModulesMap.values());
  const totalCount = selectedModulesList.reduce(
    (acc, module) => acc + module.count,
    0
  );

  return MAX_EMAIL_MODULES_SELECTION_LIMIT === totalCount;
};

export const selectedModulesCount = () =>
  store.get(moduleContentsStateSegment).size;

export const createDefaultModuleContents = (
  { count, name, label, moduleKey }: SelectedModule,
  primaryMessage: string,
  sequence: number,
  isDisabled: boolean = false,
  autoSelection: boolean = false
): ModuleContent => {
  const uniqueKey = createUniqueModuleKey(name, count);

  return {
    moduleKey,
    mapKey: uniqueKey,
    parentContentId: '',
    name,
    label: primaryMessage || label,
    translationConfig: {},
    refineConfig: {},
    status: 'Not Started' as ModuleStatus,
    hasErrorGeneratingContent: false,
    hasContentGenerated: false,
    hasImageSelected: false,
    hasOptionSelected: false,
    refinedOptions: [],
    selectedContentVersion: null,
    selectedOptionLanguage: '',
    selectedOption: null,
    selectedOptionId: '',
    selectedImage: null,
    selectedImageName: '',
    selectedImageCaption: '',
    sequence,
    generatePayload: null,
    isDisabled,
    contentId: '',
    primaryMessage,
    autoSelection,
    selectedOptionSuperScripts: null,
    // displayLabel: primaryMessage || label,
  };
};

export const addModules = (
  { count, name, label, moduleKey }: SelectedModule,
  primaryMessage: string,
  sequence: number,
  isDisabled: boolean = false,
  autoSelection: boolean = false
) => {
  const moduleContents = new Map(store.get(moduleContentsStateSegment));
  const moduleContent = createDefaultModuleContents(
    { count, name, label, moduleKey },
    primaryMessage,
    sequence,
    isDisabled,
    autoSelection
  );
  moduleContents.set(moduleContent.mapKey, moduleContent);
  store.set(moduleContentsStateSegment, moduleContents);
};

export const resetGeneratedContents = () => {
  store.set(contentListStateSegment, RESET);
  resetTranslationState();
  store.set(contentTranslationStateSegment, RESET);
  store.set(contentImagesStateSegment, RESET);
  store.set(imagesDataStateSegment, RESET);
  store.set(imageCaptionStateSegment, RESET);
  store.set(contentFeedbackStateSegment, RESET);
  store.set(currentSelectedLanguageStateSegment, RESET);
};

export const resetAllEmailModules = () => {
  resetGeneratedContents();
  store.set(contentPaginationStateSegment, RESET);
  store.set(contentClaimsStateSegment, RESET);
  store.set(refineOptionsStateSegment, RESET);
  store.set(emailContentParentId, RESET);
};

export const resetEmailModulesIntialStates = () => {
  store.set(selectedModulesStateSegment, RESET);
  store.set(moduleContentsStateSegment, RESET);
  store.set(activeEmailScreenStateSegment, RESET);
};

export const canEnableEmailAttributes = () => {
  const moduleContents = store.get(moduleContentsStateSegment);

  const selectedModules = Array.from(moduleContents.values()).filter(
    (module) =>
      module.name !== EMAIL_ATTRIBUTES_NAME && module.name !== EMAIL_INTRO_NAME
  );

  return selectedModules.length === 0
    ? false
    : selectedModules.every((module) => module.hasOptionSelected);
};

export const hasAllModulesBecameReady = () => {
  const moduleContents = store.get(moduleContentsStateSegment);

  return Array.from(moduleContents.values()).every(
    (module) => module.hasOptionSelected
  );
};

export const getEncodedUserNtid = () => {
  const { userNtidEncoded } = store.get(userDetailSegment);

  return userNtidEncoded;
};

/**
 * Checks whether a given contentId exists in the contentImagesState and if any image has a caption.
 * @param contentId - The ID of the content to check.
 * @returns A boolean indicating whether the contentId exists and has at least one image with a caption.
 */
export const doesImageCaptionExists = (contentId: string): boolean => {
  // Retrieve the current state of content images
  const contentImagesState = store.get(contentImagesStateSegment);

  // Use Object.prototype.hasOwnProperty.call to check if the contentId exists
  if (Object.prototype.hasOwnProperty.call(contentImagesState, contentId)) {
    const optionIdsHashMap = contentImagesState[contentId];

    // Use Object.values to iterate over each optionId's data
    return Object.values(optionIdsHashMap).some((data) =>
      data.images.some((image) => image.caption && image.caption.trim() !== '')
    );
  }
  return false;
};

// Function to check if imagesRecommend exists
export const doesImageRecommendExists = (
  data: GeneratedContentDataType
): boolean => {
  if (data.content && 'imagesRecommend' in data.content) {
    // for now only checking email modules
    const moduleContent = data.content as GeneratedEmailModuleContentDataType;

    return (
      moduleContent.imagesRecommend !== undefined &&
      moduleContent.imagesRecommend.length > 0
    );
  }
  return false;
};

/**
 * Checks whether a given imageId within a specific optionId and contentId has a caption.
 * @param contentId - The ID of the content to check.
 * @param optionId - The ID of the option to check within the content.
 * @param imageId - The ID of the image to check within the option.
 * @returns A boolean indicating whether the specified image has a caption.
 */
export const doesSpecificImageCaptionExist = (
  contentId: string,
  optionId: string,
  imageId: string
): boolean => {
  // Retrieve the current state of content images
  const contentImagesState = store.get(contentImagesStateSegment);

  // Check if the contentId exists in the state
  if (Object.prototype.hasOwnProperty.call(contentImagesState, contentId)) {
    const optionIdsHashMap = contentImagesState[contentId];

    // Check if the optionId exists within the contentId
    if (Object.prototype.hasOwnProperty.call(optionIdsHashMap, optionId)) {
      const imagesArray = optionIdsHashMap[optionId].images;

      // Find the specific image by imageId and check if it has a caption
      return imagesArray.some(
        (image) =>
          image.id === imageId && image.caption && image.caption.trim() !== ''
      );
    }
  }
  return false;
};

export const sortObjectArray = <T>(
  array: T[],
  key: keyof T,
  sortOrder: SortOrder.ASCENDING | SortOrder.DESCENDING = SortOrder.ASCENDING
): T[] => {
  return [...array]?.sort?.((a, b) => {
    const valueA = (a?.[key] as string)?.toString();
    const valueB = (b?.[key] as string)?.toString();

    return sortOrder === SortOrder.ASCENDING
      ? valueA?.localeCompare(valueB)
      : valueB?.localeCompare(valueA);
  });
};

export const getRefinedCount = (): number => {
  const { contentList } =
    (store.get(contentListStateSegment) as {
      contentList: Array<{ apiPayloadUsed: { operationType: string } }>;
    }) || {};

  // Count the number of items with isRefineApplied set to true
  return contentList?.reduce((count, item) => {
    return item?.apiPayloadUsed?.operationType === operationType?.REFINE
      ? count + 1
      : count;
  }, 0);
};

export const getRegenerationCountLeft = () => {
  const { contentList } = store.get(contentListStateSegment) as {
    contentList: Array<{ apiPayloadUsed: { operationType: string } }>;
  };

  const noOfRegenerationDone =
    contentList?.filter((item) => {
      return item?.apiPayloadUsed?.operationType === operationType?.REGENERATE;
    })?.length || 0;

  return 3 - noOfRegenerationDone;
};

// Function to determine if the regeneration limit is reached
export const isRegenerationLimitReached = (): boolean => {
  const { contentList } = store.get(contentListStateSegment) as {
    contentList: Array<{ apiPayloadUsed: { operationType: string } }>;
  };

  const noOfRegenerationDone =
    contentList?.filter((item) => {
      return item?.apiPayloadUsed?.operationType === operationType?.REGENERATE;
    })?.length || 0;

  return noOfRegenerationDone >= 3;
};

export const updateRecentTranslations = (
  contentId: string,
  contentVersion: string,
  langCode: string
) => {
  const recentTranslations = store.get(recentTranslationsStateSegment);
  const currentContentTranslations = recentTranslations[contentId] || {};
  const recentTranslationsList =
    currentContentTranslations?.[contentVersion] || [];

  // Ignore adding the language to the list if it is already present
  if (recentTranslationsList?.includes(langCode)) return;

  if (recentTranslationsList?.length === 0) {
    recentTranslationsList?.push(langCode);
  } else {
    recentTranslationsList?.splice(1, 0, langCode);
  }

  if (recentTranslationsList?.length > MAX_RECENT_TRANSLATIONS_LIMIT) {
    recentTranslationsList?.pop();
  }

  store.set(recentTranslationsStateSegment, {
    ...recentTranslations,
    [contentId]: {
      ...currentContentTranslations,
      [contentVersion]: recentTranslationsList,
    },
  });
};
